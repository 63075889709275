<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app clipped-left>
            <v-toolbar-title style="font-size: 24px!important;">
                <router-link :to="{ name: 'front' }" style="text-decoration: none;" v-if="!isEnterpriseBranding">
                    <!-- <span class="purple--text text--darken-1">Liberty</span><span class="purple--text text--lighten-2">DNS</span> -->
                    <v-img alt="LibertyDNS" src="@/assets/logo/libertydns/LibertyDNS_light_198x48.png" class="mr-1 float-left" width="148" height="36" fit="inside"></v-img>
                </router-link>
                <router-link :to="{ name: 'account-dashboard', params: { accountId: account.id } }" style="text-decoration: none;" v-if="isEnterpriseBranding">
                    <span :style="primaryTextStyle">{{ account.name }}</span>
                    <!-- TODO: for enterprise branding, show the enteprise logo here; add @ LibertyDNS ? or maybe a small libertydns logomark? or else the only hint is the tab title -->
                </router-link>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                :color="primaryColor"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <template v-if="isAuthenticated && account">
                <v-menu offset-y right open-on-click open-on-hover close-delay="100">
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" :style="primaryIconButtonStyle">
                            <font-awesome-icon :icon="['fas', 'folder-open']" fixed-width></font-awesome-icon>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <!-- TODO: add query parameters for new, unfulfilled orders that need to be processed, and change label to 'New Orders'; from that page admin can change search parameters to see any orders -->
                        <v-list-item :to="{ name: 'account-search-domain', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'globe']" :color="primaryColor" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Domains
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- TODO: bring this users item back when we implement the user management. disabled for now beacuse it's confusing for people to have this option but nothing to do on the users page -->
                        <!-- <v-list-item :to="{ name: 'account-search-user', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'user-friends']" :color="primaryColor" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Users
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                    </v-list>
                </v-menu>
                <TextButton class="text-headline" style="font-size: 1.1em; line-height: 1.1em;" @click="navigateToAccountDashboard">
                    <span v-html="accountNameNbsp"></span>
                </TextButton>
                <v-menu offset-y right open-on-click open-on-hover close-delay="100">
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" :style="primaryIconButtonStyle">
                            <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item :to="{ name: 'account-create-domain', params: { accountId: $route.params.accountId } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'globe']" :color="primaryColor" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Add Domain
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <v-btn icon :style="primaryIconButtonStyle" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
            </v-btn>
            <v-menu v-model="helpMenu" offset-y open-on-click open-on-hover close-delay="100">
                <template #activator="{ on, attrs }">
                    <v-btn icon :style="primaryIconButtonStyle" v-bind="attrs" v-on="on">
                        <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                        <font-awesome-icon :icon="['fas','question']" fixed-width/>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        Questions or comments?
                        <a href="https://liberty.io/contact/" target="_blank">Contact us!</a>
                    </v-card-text>
                </v-card>
            </v-menu>
            <!-- <div v-if="!isAuthenticated">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="purple" right>
                        <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                        <v-tab to="/about">About</v-tab>
                        <v-tab to="/login">Login</v-tab>
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y open-on-click open-on-hover close-delay="100">
                        <template #activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on">
                                <font-awesome-icon right class="purple--text" :icon="['fas', 'bars']" fixed-width/>
                            </v-btn>
                        </template>
                        <v-tabs vertical slider-color="purple">
                            <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                            <v-tab to="/about">About</v-tab>
                            <v-tab to="/login">Login</v-tab>
                        </v-tabs >
                    </v-menu>
                </div>
            </div> -->
            <v-btn outlined :style="primaryIconButtonStyle" v-if="!isAuthenticated && this.$route.name !== 'login'" @click="login">
                <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                <!-- <font-awesome-icon :icon="['fas','question']" fixed-width/> -->
                Sign in
            </v-btn>
            <v-menu offset-y open-on-click open-on-hover close-delay="100" v-if="isAuthenticated">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :style="primaryIconButtonStyle">
                        <Avatar :attr="mainProfile" :size="28"/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item v-if="user">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ user.display_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ user.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-dashboard' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'chart-line']" :color="primaryColor" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Dashboard
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'profile' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'user']" :color="primaryColor" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Profile
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-account-list' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" :color="primaryColor" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Accounts
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <template v-if="isPermitServiceAdmin">
                        <v-divider></v-divider>
                        <v-list-item :to="{ name: 'system-dashboard' }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'id-badge']" :color="primaryColor" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Service Admin
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-divider></v-divider>
                    <v-list-item @click="onLogout">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'shield-alt']" :color="primaryColor" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Logout
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </nav>
</template>

<style scoped>
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';
import TextButton from '@/components/TextButton.vue';

export default {
    components: {
        Avatar,
        TextButton,
    },

    data: () => ({
        helpMenu: false,
    }),

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            account: (state) => state.account,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
        isPermitServiceAdmin() {
            return this.isAuthenticated && Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEnterpriseBranding() {
            return false; // TODO: when the selected account has this feature enabled, then we should detect and return true here, so we'll show their logo on the left side of the navbar instead of the LibertyDNS logo
        },
        accountNameNbsp() {
            // the intent is to replace spaces with &nbsp; but because we have to use v-html to render this, we ALSO need to escape angle brackets and quotes to prevent XSS attacks with the account name itself (because user can edit the account name, and then admins can look at it, so the attack would be an authorized user trying to take over an admin's session)
            return this.account?.name
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/, '&')
                .replace(/ /g, '&nbsp;');
        },
    },

    methods: {
        login() {
            const { fullPath } = this.$router.currentRoute;
            this.$router.push({ path: '/login', query: { next: fullPath } });
        },
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push({ name: 'front' });
            }
        },
        search() {
            if (this.$route.name === 'search') {
                // reset the search input to empty string
                this.$bus.$emit('search', '');
            } else {
                this.$router.push({ name: 'search' /* , query: { q: this.searchQuery } */ });
            }
        },
        navigateToAccountDashboard() {
            this.$router.push({ name: 'account-dashboard', params: { accountId: this.account.id }, query: { t: Date.now() } });
        },
    },

    mounted() {
        console.log(`navbar route params ${JSON.stringify(this.$route.params)}`);
        if (this.$route.params.realmId) {
            console.log(`navabar realm ${this.$route.params.realmId}`);
        } else {
            console.log('navbar no realm');
        }
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
